.modals-container{
  position: fixed;
  left:0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y:auto;
}

.modals-content{
  position: absolute;
  top:20px;
  padding: 15px;
  height: auto;
  width: 70vw;
  max-width: 900px;
  min-width: 340px;
  background-color: #fff;
  margin-top: 20px;
  margin-bottom: 50px;
  text-align: center;
  padding-inline: 30px;
  
}

.modals-header{
  padding: 10px;
}
.modals-header h2{
  font-weight: bold;
}
.modals-header h2 a{
  color: #fcbc1d;
}

.modals-footer{
  text-align: center;
  padding: 10px;
}

.modals-footer span{
  font-weight: bold;
}

.modals-body{
  font-size: 20px;
  padding: 10px;
  border-top: 1px solid #ccc;
}

.modals-text{
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  color: #545454;
  font-weight: lighter;
  font-size: 16px;
  margin-block: 15px;
}


