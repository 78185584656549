.navMenu{
  display: none;
}

.dropdown {
  padding: 12px;
}

.dropdown a{
  text-decoration: none;
  color: antiquewhite;
  font-size: 15px;
}


.dropdown a:hover{
  color:#fcbc1d;
}

@media only screen and (max-width: 900px) {
  .navElt{
      display: none;
  }
  .navMenu{
    display: block;
    background-color: #fcbc1d;
    color: black;
    font-size: 17px;
    border-radius: 10%;
    padding: 5px 15px;
    margin: 5px;
    min-width: 100px;
    border-color: transparent;
  }
}