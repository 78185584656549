.box:hover{
  background-color: #579bde61;
  transform: scale(.93);
  transition: transform 0.5s, background-color 1s;
}

.box{
  max-width: 400px;
  min-height: 250px;
  /* border: solid rgb(75, 75, 75) 0.1px; */
  margin:5px;
}

.bgbox{
  background-color: rgba(0, 0, 0,  0.14);
  width: 100%;
  height: 100%;
  padding: 15px;
}

.bgbox h1, p{
  font-weight: bolder;
}

.bgbox p{
  padding-top: 10px;
}

.bgbox:hover{
  background-color: rgba(87, 154, 222, 0.8);
}