.skillbox p{
  color: #dae4eb;
  font-size: 18px;
  font-weight: lighter;
}

.skillbox{
  max-width: 420px;
  padding: 15px;
}

.skillbox h4{
  font-weight: bold;
}

.highlight{
  color: #fcbc1d;
}